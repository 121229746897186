import axios from "axios";

class DataPanelService {
  constructor() {
    (this.baseURL = process.env.VUE_APP_BASE_URL),
      (this.api = axios.create({
        baseURL: this.baseURL,
        headers: {
          "Content-Type": "application/json",
          // Aquí puedes añadir otros headers si es necesario
        },
      }));
  }
  async getData() {
    try {
      const response = await this.api.get("/estadisticas/dashboard");
      return response.data;
    } catch (error) {
      throw new Error("Error al obtener datos del servidor");
    }
  }
  async getDataParameter(date) {
    let desde = date.desde;
    let hasta = date.hasta;
    try {
      const response = await this.api.get(
        `/estadisticas/dashboard?fechaDesde=${desde}&fechaHasta=${hasta}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Error al obtener datos del servidor");
    }
  }
}
export default new DataPanelService();
