import axios from "axios";

const state = () => ({
  productos: [],
  loderProducts: false,
});
const getters = {
  productosState(state) {
    return state.productos;
  },
  loderTableProductsState(state) {
    return state.loderProducts;
  },
};
const mutations = {
  getProductosLoad(state, payload) {
    state.productos = payload;
    state.loderProducts = false;
  },
};
const actions = {
  getProductos({ commit, state }, refresh) {
    return new Promise(async (resolve) => {
      try {
       
        if (state.productos.length !== 0 && !refresh) {
          console.log("entro en el return de productos");
          resolve();
          return;
        }
        state.productos = [];
        state.loderProducts = true;
        console.log('>>>>>',state.productos);
        let resp = await axios.get(process.env.VUE_APP_BASE_URL + "productos");
        console.log("entro en el get productos");
        commit("getProductosLoad", resp.data);
        resolve();
      } catch (e) {
        console.log("erroorrrr",e);
      }
    });
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
